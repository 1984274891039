<template>
    <span class="fieldset_label" v-bind="$attrs"><slot></slot></span>
</template>

<script>
export default { name: "SettingsHelpText" };
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.fieldset_label {
    @extend %body2_style;
    display: inline-block;
    margin-bottom: 16px;
}
</style>
