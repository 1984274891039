<template>
    <GenericPage
        :header-props="{
            title: $t('cp__settings__research_api__title'),
            subtitle: $t('cp__settings__research_api__subtitle'),
            disableBackButton: true,
        }"
    >
        <SettingsForm class="settings_form">
            <SettingsTextBlock
                :title="$t('cp__settings__review_api__available_connections__title')"
                class="settings_block"
            >
                <p class="text">
                    {{ $t("cp__settings__review_api__available_connections__message") }}
                    <a
                        class="link"
                        target="_blank"
                        href="https://intercom.help/feedbackcompany/nl/collections/2424875-api-review-research-portal"
                        >{{ $t("cp__settings__review_api__available_connections__linktext") }}</a
                    >.
                </p>
            </SettingsTextBlock>
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__settings__research_api__api_key__title')"
            >
                <SettingsSection :label="$t('cp__settings__research_api__api_key')">
                    <SettingsHelpText>{{
                        $t("cp__settings__research_api__api_key__label")
                    }}</SettingsHelpText>
                    <FieldToClipboard v-if="apiKey" :value="apiKey" @onCopied="triggerCopyToast" />
                    <Button v-else buttonStyle="secondary" @click.native="popupActive = true">{{
                        $t("cp__settings__research_api__api_key__request_button")
                    }}</Button>
                </SettingsSection>
            </SettingsBlock>
        </SettingsForm>
        <ConfirmPopup
            v-if="popupActive"
            :title="$t('cp__settings__research_api__api_key__popup_title')"
            :message="$t('cp__settings__research_api__api_key__popup_message')"
            :confirmBtn="$t('cp__settings__research_api__api_key__popup_confirm_btn')"
            :cancel-btn="$t('cp__generic__cancel')"
            @confirm="requestNewApiKey"
            :confirmBtnIsLoading="loading"
            @cancel="popupActive = false"
            closeAllowed
        />
    </GenericPage>
</template>

<script>
import FieldToClipboard from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/FieldToClipboard.vue";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import * as Sentry from "@sentry/vue";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";
import SettingsHelpText from "@/components/Settings/Molecules/SettingsHelpText.vue";
import SettingsTextBlock from "@/components/Settings/Molecules/SettingsTextBlock.vue";
import updateEvAPIKey from "@/graphql/settings/updateEvAPIKey.gql";

export default {
    name: "ResearchApiSettings",
    components: {
        Button,
        FieldToClipboard,
        GenericPage,
        SettingsForm,
        SettingsBlock,
        SettingsSection,
        SettingsHelpText,
        SettingsTextBlock,
        ConfirmPopup,
    },
    data() {
        return {
            popupActive: false,
            loading: false,
            apiKey: null,
        };
    },
    methods: {
        async triggerCopyToast(value) {
            await this.$store.dispatch("pushNotification", {
                type: "success",
                title: this.$t("cp__general__copied"),
                message: this.$t("cp__general__copied__message", { value }),
            });
        },
        async requestNewApiKey() {
            this.loading = true;
            try {
                const { data } = await this.$apollo.mutate({ mutation: updateEvAPIKey });
                this.apiKey = data.updateApiKey.apiKey;
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__settings__research_api__api_key__popup_title"),
                    message: this.$t("cp__settings__research_api__api_key__success_msg"),
                });
                this.popupActive = false;
            } catch (e) {
                Sentry.captureException(e);
                /* eslint-disable no-console */
                console.error(e);
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__settings__research_api__api_key__popup_title"),
                    message: this.$t("cp__settings__research_api__api_key__error_msg"),
                });
            }
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.settings_form {
    width: 100%;
    max-width: 950px;
    margin-bottom: 40px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:last-of-type {
        margin-bottom: 40px;
    }
}

.text {
    max-width: 770px;
    margin: 0;
    letter-spacing: 0;

    .link {
        @extend %anchor_style;
    }
}
</style>
