import { render, staticRenderFns } from "./SettingsTextBlock.vue?vue&type=template&id=4fe41795&scoped=true&"
import script from "./SettingsTextBlock.vue?vue&type=script&lang=js&"
export * from "./SettingsTextBlock.vue?vue&type=script&lang=js&"
import style0 from "./SettingsTextBlock.vue?vue&type=style&index=0&id=4fe41795&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe41795",
  null
  
)

export default component.exports